<!--
 * @Descripttion: 单个文件
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: lixianbing
 * @LastEditTime: 2020-3-24 16:10:14
 -->
<template>
    <div class="single" :title="item.name" @click="leftClick(item, $event)" :class="{ 'active': item.choose }">
        <!-- 类型为文件时 -->
        <div class="imageshow">
            <template v-if="item.fileType===1">
                <img :src="item.fileVisitUrl + '?w=161'" />
            </template>
            <template v-else>
                <svg class="iconfont table-svg" aria-hidden="true">
                    <use :xlink:href="item.icon"></use>
                </svg>
            </template>
            
        </div>
        <p class="single_p" v-if="!item.showRename">{{ item.name }}</p>
        <div class="renames">
            <rename v-if="item.showRename" @close="closeRename(item)" />
        </div>
        <div style="width: 100%;height: 60%;position: absolute;left: 0;top: 0;">
            <div class="checkboxs" v-on:click="changeActive"><i class="iconfont fileicon-test"></i></div>
        </div>
        <!-- <img-preview :layer="layer" :list="item" /> -->
    </div>
</template>

<script>
    import {
        allList,
        typeList
    } from '@/api/file'
    export default {
        components: {
            rename: () => import('./rename'),
            // imgPreview: () => import('@/components/preview/img') // 预览功能
        },
        props: {
            item: {
                type: Object,
                default: () => {
                    return {
                        choose: false
                    }
                }
            },
        },
        model: {
            prop: 'singleName',
            event: 'singleName'
        },
        data() {
            return {
              layer: {      // 图片预览专用
                show: false,
                active: {},
                data: []
              }
            }
        },
        watch: {
            name(newVal) {
                this.$emit('singleName', newVal)
            }
        },
        created() {

        },
        mounted() {

        },
        methods: {
            // 关闭事件
            closeRename(row) {
                // 判断新建的文件夹还是重命名文件夹
                if (row.id) {
                    row.showRename = false
                }
                // else {
                //     this.data.shift(0, 1)
                // }
            },
            changeActive() {
                this.$set(this.item, 'choose', !this.item.choose)
                console.log(this.item.choose)
            },
            // 左键点击事件
            leftClick(e) {
              // 判断是文件点击还是文件夹点击
              if (this.item.fileType === 1 ) {
                this.showThumb(this.item, e)
              } else if(this.item.choose) {
                   e.stopPropagation()
              }else{
                  this.dirJump(this.item)
              }
            },
            // 路由跳转
            dirJump() {
              this.$store.commit('file/navPush', this.item)
            },
            // 打开预览功能
            showThumb(e) {
              if (this.item.fileType === 1) {
                this.layer.active = this.item
                this.layer.position = {
                  x: e.clientX + document.documentElement.scrollLeft - document.documentElement.clientLeft,
                  y: e.clientY + document.documentElement.scrollTop  - document.documentElement.clientTop
                }
                this.layer.show = true
              } else {
                window.open(this.item.fileDownloadUrl)
              }
              
            },
           
        }
    }
</script>

<style scoped="" lang="scss">
    .thumb {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        height: calc(100vh - 269px);
        overflow-y: auto;
        background-color: #ececec;
        padding-bottom: 20px;
    }

    .single {
        width: 229px;
        height: 220px;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 13px;
        transition: all 0.2s ease 0s;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid #B9CBD7;
        position: relative;
        cursor: pointer;

        p {
            text-align: center;
            margin-top: -10px;
            color: #444444;
            white-space: nowrap;
            padding-left: 40px;
            padding-right: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
       &.active{
           background-color:rgba(102, 120, 140, 0.14);
           .checkboxs{opacity: 1 !important;}
       }
    }

    .single:hover {
        background: rgba(102, 120, 140, 0.14);
        .checkboxs{opacity: 0.5;}
    }
    
    .imageshow {
       width: 161px;
       height: 118px;
       margin: 30px 34px;
       overflow: hidden;
    }img{
        width: 100%;
        // height: 100%;
        border-radius: 5px;
    }

    .table-svg {
      width:84px;
      height:84px;
      margin: 19px 38px;
    }

    .renames {
        width: 200px;
        margin: auto;
        height: auto;
        margin-top: -23px;
    }

    .checkboxs {
        position: absolute;
        top: 9px;
        right: 13px;
        height: 21px;
        width: 21px;
        cursor: pointer;
        background: #3489FE;
        color: white;
        border-radius: 50%;
        text-align: center;
        opacity: 0;
    }
    
</style>
